



















































































import { Vue, Component } from "vue-property-decorator";
import axios from "axios";
import { User } from "@/types";

@Component({
  metaInfo() {
    return {
      title: "Kullanıcılar / Admin"
    };
  }
})
export default class AdminUsersView extends Vue {
  search = "";
  loading = false;
  headers = [
    {
      text: "e-Posta",
      align: "start",
      filterable: true,
      value: "email"
    },
    { text: "Ad", value: "firstName" },
    { text: "Soyad", value: "lastName" },
    { text: "Onay", value: "emailConfirmed", width: "90px" },
    { text: "", value: "actions", sortable: false, width: "50px" }
  ];
  items = [] as User[];
  selectedItem = {};
  dialog = false;
  dialogContent = "";

  async getItems() {
    try {
      this.loading = true;

      var res = await axios.get("/api/user/get-all", {
        headers: this.$store.getters["auth/requestAuthHeader"]
      });

      this.items = res.data;
    } catch (e: unknown) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: `Kullanıcı listesi alınamadı.`
      });
    } finally {
      this.loading = false;
    }
  }

  async deleteUser(user: User) {
    const confirmation = await this.$confirm("Bu işlem geri alınamaz. Kullanıcıyı silmek istediğinizden emin misiniz?", { title: "Emin misiniz?" });

    if (!confirmation) return;

    try {
      console.log(user);

      // Kullanıcıyı sil
      const authHeader = this.$store.getters["auth/requestAuthHeader"];
      var res = await axios.delete(`/api/user/${user.id}`, {
        headers: authHeader
      });

      this.$notify({
        type: "success",
        title: "İşlem Tamam",
        text: "Kullanıcı başarıyla silindi."
      });

      await this.getItems();
    } catch (e) {
      this.$notify({
        type: "eror",
        title: "Hata!",
        text: "Kullanıcı silinemedi."
      });

      throw e;
    }
  }

  changePassword(user: User) {
    console.log(user);
  }

  async mounted() {
    await this.getItems();
  }
}
